import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Footer from '../components/Footer';
import Container from '../components/Container';
import Header from '../components/Header';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';
import NaturesBakerySection from '../components/clients/NaturesBakerySection';
import UsWaterSystemsSection from '../components/clients/UsWaterSystemsSection';
import TopBrandsSection from '../components/sections/TopBrandsSection';

const ThankYouPage = () => (
  <Layout>
    <Seo title="Thank You" />

    <Header graphic={undefined}>Thank You</Header>

    <section className="bg-white text-sp-dark">
      <Container className="my-24 ">
        <div className="prose prose-lg max-w-none prose-headings:font-normal prose-h2:text-4xl prose-h3:text-3xl text-lg">
          <p>Thank you for signing up for our newsletter. You will receive an email from us soon!</p>
        </div>
      </Container>
    </section>

    <UsWaterSystemsSection />
    <NaturesBakerySection />

    <TopBrandsSection containerClassName="max-w-screen-lg" showOneLineOnly showCards={false} />

    <BuildRelationshipsSection />

    <Footer />
  </Layout>
);

export default ThankYouPage;
